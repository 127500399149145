import React, { createContext, useState, useEffect } from "react";

//  states default value
const defaultValue = {
  orders: [],
  addOrder: () => {},
  removeOrder: () => {},
  updateOrder: () => {},
  clearQuote: () => {},
  isOrderExist: () => {},
  isFormPackaging: false,
  setIsFormPackaging: () => {},
};

//  init context
export const StoreContext = createContext(defaultValue);

//  states wrapper
export const StoreProvider = ({ children }) => {
  //  state for orders than can be used as a cart
  const [orders, setOrders] = useState(
    JSON.parse(localStorage.getItem("orders"))
      ? JSON.parse(localStorage.getItem("orders"))
      : []
  );

  const [isFormPackaging, setIsFormPackaging] = useState(false);

  //  orders watcher if users add/remove item
  useEffect(() => {
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  //  adding product
  const addOrder = (
    //  expected object for each item
    item = {
      id: "",
      name: "",
      quantity: 0,
      image: "",
    }
  ) => {
    //  check if item is already in the array
    const isOrderExist = orders.some((order) => order.id === item.id);

    //  only add item if it doesn't exist yet
    if (!isOrderExist) {
      setOrders([...orders, item]);
    }
  };

  //  removing product
  const removeOrder = (id) => {
    //  remove product by its id value
    const updatedOrders = orders.filter((order) => order.id !== id);
    setOrders(updatedOrders);
  };

  //  update selected product quantity
  const updateOrder = (id, qty) => {
    setOrders(
      //  return new products array with the update quantity
      orders.map((order) => {
        if (id === order.id) {
          //  prevent number value to go below 0
          order.quantity = qty > 0 ? qty : "";
        }
        return order;
      })
    );
  };

  const clearQuote = () => {
    setOrders([]);
  };

  const isOrderExist = (id) => {
    const isExist = orders.some((order) => order.id === id);
    return isExist;
  };

  return (
    <StoreContext.Provider
      value={{
        orders,
        addOrder,
        removeOrder,
        updateOrder,
        clearQuote,
        isOrderExist,
        isFormPackaging,
        setIsFormPackaging,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
